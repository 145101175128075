import { computed, onMounted, ref } from 'vue'
import emailValidator from '../../foundation/js/inputValidators/emailValidator'
import notEmptyInput from '../../foundation/js/inputValidators/notEmptyInput'
import getSalesforceId from '../../foundation/js/sendCalculation/getSalesforceId'
import sendCalculationSubmit from '../../foundation/js/sendCalculation/sendCalculationSubmit'
import getUserInformation from '../../foundation/js/sendCalculation/getUserInformation'
import getGeneralComputedValues from '../../foundation/js/signupFlows/getGeneralComputedValues'
import handleValidateInputAndProceed from '../../foundation/js/signupFlows/handleValidateInputAndProceed'
import { registerGtmDataLayer } from '../../../../Features/Blocks/Video/gtmDataLayerService'

import SignupStepReceipt from '../../../../Features/Blocks/Newsletters/NewslettersSignup/SignupStepReceipt/SignupStepReceipt.vue'
import ContactStepIntro from '../ContactStepIntro/ContactStepIntro.vue'
import SendCalculationStepContent from '../SendCalculationStepContent/SendCalculationStepContent.vue'
import SendCalculationStepInfo from '../SendCalculationStepInfo/SendCalculationStepInfo.vue'
import ContactStepComments from '../ContactStepComments/ContactStepComments.vue'

export default {
    name: 'SendCalculation',
    components: {
        ContactStepIntro,
        SendCalculationStepInfo,
        SendCalculationStepContent,
        ContactStepComments,
        SignupStepReceipt,
    },
    props: {
        labels: {
            type: Object,
            required: true,
        },
        salesforceidApiUrl: {
            type: String,
            required: true,
        },
        sendCalculationApiUrl: {
            type: String,
            required: true,
        },
        userInformationApiUrl: {
            type: String,
            required: true,
        },
        calculatorType: {
            type: String,
            required: true,
        },
        backgroundColor: {
            type: String,
            required: false,
            default: 'gamma',
        },
        initStartFlow: {
            type: Boolean,
            required: false,
            default: false,
        },
        redirectLink: {
            type: Object,
            required: false,
        },
        onCloseCallback: {
            type: Function,
            required: false,
        },
    },
    setup(props) {
        const bgColorClassName = computed(() => {
            return `nyk-send-calculation--${props.backgroundColor}`
        })

        const sendCalculationClassNames = computed(() => {
            return `${bgColorClassName.value}`
        })

        const notRequiredInput = (input) => {
            return true
        }

        // this object contains the entire set of inputs available for the complete form.
        const userInput = ref([
            {
                field: 'firstname',
                value: '',
                validator: notEmptyInput,
                isValid: false,
            },
            {
                field: 'lastname',
                value: '',
                validator: notEmptyInput,
                isValid: false,
            },
            {
                field: 'email',
                value: '', // you can prefill the inputfield by adding a value here.
                validator: emailValidator,
                isValid: false,
            },
        ])

        // this object contains stepinformation.
        // all steps are described here
        let stepsConfigAndOrder = ref([
            {
                step: 1,
                name: 'SendCalculationStepInfo',
                completed: false,
                isCountableStep: false,
                texts: {
                    boldPrefixText: props.labels.boldPrefixText,
                    mainText: props.labels.mainText,
                    buttonText: props.labels.buttonText,
                },
            },
            {
                step: 2,
                name: 'SendCalculationStepContent',
                completed: false,
                isCountableStep: false,
                texts: {
                    title: props.labels.title,
                    summary: props.labels.consent,
                },
            },
            {
                step: 3,
                name: 'SignupStepReceipt',
                completed: false,
                isCountableStep: false,
                texts: {
                    title: props.labels.receiptTitle,
                    summary: props.labels.receipt,
                    finishSignupLabel: props.labels.finishSignupLabel ?? '',
                },
            },
        ])

        onMounted(() => {
            if (props.initStartFlow) {
                stepsConfigAndOrder.value[0].completed = true
            }
        })

        const displayInputError = ref(false)

        const {
            getNamedFieldInputData,
            resetSignupForm,
            currentStepName,
            currentStepTexts,
            hasPreviousStep,
            stepCounterText,
            showFieldErrorMessage,
            returnToPreviousStep,
        } = getGeneralComputedValues(userInput.value, stepsConfigAndOrder.value, props.labels)

        const validateInputAndProceed = function (inputData) {
            const validSuccess = handleValidateInputAndProceed(
                inputData,
                userInput.value,
                stepsConfigAndOrder.value,
                currentStepName.value,
                submitData
            )

            displayInputError.value = validSuccess
        }

        const submitData = async function (inputData) {
            const body = {
                firstName: inputData.find((field) => field.field === 'firstname').value,
                lastName: inputData.find((field) => field.field === 'lastname').value,
                email: inputData.find((field) => field.field === 'email').value,
            }

            let beregnTracking = {
                event: `track-event.${props.calculatorType}_sendberegning`,
                eventCategory: 'sendberegning',
                eventAction: props.calculatorType,
                eventLabel: 'submit',
                eventValue: '0',
                userType: 'privat',
            }

            registerGtmDataLayer(beregnTracking)

            await getUserInformation(body, props.userInformationApiUrl)
            // check if the user exists in salesforce
            const response = await getSalesforceId(body, props.salesforceidApiUrl)

            const sendCalculationBody = {
                email: inputData.find((field) => field.field === 'email').value,
                firstName: inputData.find((field) => field.field === 'firstname').value,
                lastName: inputData.find((field) => field.field === 'lastname').value,
                ...(typeof response.data === 'string' && { salesforceId: response.data }),
                calculatorType: props.calculatorType,
            }

            await sendCalculationSubmit(sendCalculationBody, props.sendCalculationApiUrl)
        }

        return {
            currentStepTexts,
            displayInputError,
            currentStepName,
            getNamedFieldInputData,
            validateInputAndProceed,
            stepCounterText,
            returnToPreviousStep,
            showFieldErrorMessage,
            hasPreviousStep,
            resetSignupForm,
            sendCalculationClassNames,
        }
    },
}
