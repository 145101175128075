import FormRadioGroupInput from '../FormRadioGroupInput/FormRadioGroupInput.vue'
import FormGenericInput from '../FormGenericInput/FormGenericInput.vue'
import { computed, onMounted, onUpdated, ref, watch } from 'vue'
import reverseThousandCommaSeparation from '../../js/reverseThousandCommaSeparation'
import applyThousandSeperator from '../../js/applyThousandSeperator'

export default {
    name: 'LoanCalculatorInputs',
    components: {
        FormRadioGroupInput,
        FormGenericInput,
    },
    props: {
        labels: {
            type: Object,
            required: true,
        },
        housingTypes: {
            type: Array,
            required: true,
        },
        initData: {
            type: Object,
            required: false,
        },
        onChangeCallback: {
            type: Function,
            required: true,
        },
        displayErrors: {
            type: Boolean,
            required: false,
            default: false,
        },
        showHousingTypes: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    setup(props) {
        const housePrice = ref(props.initData?.housePrice > 0 ? props.initData?.housePrice : '')
        const housePriceRaw = ref(props.initData?.housePrice ?? '')
        const ownPayment = ref(props.initData?.ownPayment > 0 ? props.initData?.ownPayment : '')
        const ownPaymentRaw = ref(props.initData?.ownPayment ?? '')
        const housingType = ref(props.initData?.housingType ?? '')
        const radioOptions = ref([])

        // error labels
        const housePriceErrormMessage = computed(() => {
            const housePriceInt = housePriceRaw.value !== '' ? parseFloat(housePriceRaw.value) : 0
            const ownPaymentInt = ownPaymentRaw.value !== '' ? parseFloat(ownPaymentRaw.value) : 0
            const priceDiff = housePriceInt - ownPaymentInt

            if ((priceDiff < minLoanAmount.value && housePriceInt > ownPaymentInt) || housePriceInt === 0) {
                return minLoanAmountErrorText.value
            }

            if (priceDiff > maxLoanAmount.value) {
                return maxLoanAmountErrorText.value
            }

            return ''
        })

        const dynamicOwnPaymentErrorMessage = computed(() => {
            const housePriceInt = housePriceRaw.value !== '' ? parseFloat(housePriceRaw.value) : 0
            const ownPaymentInt = ownPaymentRaw.value !== '' ? parseFloat(ownPaymentRaw.value) : 0

            if ((ownPaymentInt >= housePriceInt) & (ownPaymentInt > 0)) {
                return ownPaymentToHighErrorMessage.value
            }

            if (ownPaymentInt < minOwnPaymentAmount.value) {
                return ownPaymentErrorMessage.value.replace('{minDownPayment}', minOwnPaymentAmount.value)
            }

            return ''
        })

        const setRadioOptions = () => {
            const initHousingType = props.initData?.housingType

            props.housingTypes.forEach((item) => {
                const isSelected = initHousingType && initHousingType === item.keyName ? true : item.selected

                if (isSelected) {
                    housingType.value = item.keyName
                }

                radioOptions.value.push({
                    label: item.label,
                    id: item.keyName,
                    value: item.keyName,
                    checked: isSelected,
                })
            })
        }

        // set radio options and handle init input formatting
        onMounted(() => {
            setRadioOptions()
            housePrice.value = formatInput(housePrice.value)
            ownPayment.value = formatInput(ownPayment.value)
        })

        // handle callback data
        onUpdated(() => {
            if (props.onChangeCallback) {
                props.onChangeCallback({
                    housePrice: housePriceRaw.value,
                    ownPayment: ownPaymentRaw.value,
                    housingType: housingType.value,
                    defaultBankLoanTenureLength: defaultBankLoanTenureLength.value,
                    defaultMortgageLoanTenureLength: defaultMortgageLoanTenureLength.value,
                    defaultMortgageLoanInterestOnlyLength: defaultMortgageLoanInterestOnlyLength.value,
                    formValid: ownPaymentValidation() && housePriceValidation(),
                })
            }
        })

        // format input
        const formatInput = (value) => applyThousandSeperator(reverseThousandCommaSeparation(value))

        // get Current House Type Object
        const getCurrentHouseTypeObject = () => {
            if (housingType.value) {
                return props.housingTypes.find((x) => x.keyName === housingType.value)
            } else {
                return props.housingTypes.find((x) => x.selected)
            }
        }

        // get all selected object values
        const downPaymentProcent = computed(() => getCurrentHouseTypeObject()?.downPaymentProcent)
        const minLoanAmount = computed(() => getCurrentHouseTypeObject()?.minLoanAmount)
        const maxLoanAmount = computed(() => getCurrentHouseTypeObject()?.maxLoanAmount)
        const minOwnPaymentAmount = computed(() => (housePriceRaw.value * (downPaymentProcent.value / 100)).toFixed(0))
        const maxLoanAmountErrorText = computed(() => getCurrentHouseTypeObject()?.maxLoanAmountErrorText ?? '')
        const minLoanAmountErrorText = computed(() => getCurrentHouseTypeObject()?.minLoanAmountErrorText ?? '')
        const ownPaymentToHighErrorMessage = computed(() => getCurrentHouseTypeObject()?.ownPaymentToHighErrorMessage ?? '')
        const ownPaymentErrorMessage = computed(() => getCurrentHouseTypeObject()?.ownPaymentErrorMessage ?? '')
        const defaultBankLoanTenureLength = computed(() => getCurrentHouseTypeObject()?.defaultBankLoanTenureLength ?? 0)
        const defaultMortgageLoanTenureLength = computed(() => getCurrentHouseTypeObject()?.defaultMortgageLoanTenureLength ?? 0)
        const defaultMortgageLoanInterestOnlyLength = computed(
            () => getCurrentHouseTypeObject()?.defaultMortgageLoanInterestOnlyLength ?? 0
        )

        // handle input changes
        const checkAndSetInputValue = (value) => {
            let res = value
            if (!/^\d*$/.test(value)) {
                res = value.replace(/\D/g, '')
            }
            return res
        }

        watch(housePrice, (value) => {
            if (value === '') {
                housePriceRaw.value = reverseThousandCommaSeparation(value)
                housePrice.value = formatInput(value)
            }
        })

        watch(ownPayment, (value) => {
            if (value === '') {
                ownPaymentRaw.value = reverseThousandCommaSeparation(value)
                ownPayment.value = formatInput(value)
            }
        })

        const setHousePrice = (e) => {
            const cursorPosition = e.target.selectionStart
            const valueLength = e.target.value.length

            let value = e.target?.value ?? 0
            value = checkAndSetInputValue(value)
            housePriceRaw.value = reverseThousandCommaSeparation(value)
            housePrice.value = formatInput(value)

            // pre-fill ownPayment
            const newOwnPayment = parseFloat(minOwnPaymentAmount.value)
            ownPaymentRaw.value = reverseThousandCommaSeparation(newOwnPayment.toFixed(0))
            ownPayment.value = formatInput(newOwnPayment.toFixed(0))

            // set cursor position
            setTimeout(() => {
                const newValueLength = housePrice.value.toString().length
                const newCursorPosition = cursorPosition + (newValueLength - valueLength)
                e.target.setSelectionRange(newCursorPosition, newCursorPosition)
            }, 10)
        }

        const setOwnPayment = (e) => {
            const cursorPosition = e.target.selectionStart
            const valueLength = e.target.value.length

            let value = e.target?.value ?? 0
            value = checkAndSetInputValue(value)
            ownPaymentRaw.value = reverseThousandCommaSeparation(value)
            ownPayment.value = formatInput(value)

            // set cursor position
            setTimeout(() => {
                const newValueLength = ownPayment.value.toString().length
                const newCursorPosition = cursorPosition + (newValueLength - valueLength)
                e.target.setSelectionRange(newCursorPosition, newCursorPosition)
            }, 10)
        }

        const setHousingTypes = (e) => (housingType.value = e.target.value)

        // validation
        const housePriceValidation = () => {
            const priceDiff = parseFloat(housePriceRaw.value) - parseFloat(ownPaymentRaw.value)
            if (priceDiff >= minLoanAmount.value && priceDiff <= maxLoanAmount.value) {
                return true
            }
            return false
        }

        const ownPaymentValidation = () => {
            const priceDiff = parseFloat(housePriceRaw.value) - parseFloat(ownPaymentRaw.value)
            if (
                priceDiff < maxLoanAmount.value &&
                parseFloat(ownPaymentRaw.value) >= minOwnPaymentAmount.value &&
                priceDiff >= minLoanAmount.value
            ) {
                return true
            }
            return false
        }

        return {
            housePrice,
            ownPayment,
            housingType,
            radioOptions,
            dynamicOwnPaymentErrorMessage,
            housePriceErrormMessage,
            ownPaymentValidation,
            housePriceValidation,
            setOwnPayment,
            setHousePrice,
            setHousingTypes,
        }
    },
}
