<script>
import options from './FormRadioGroupInput.options'
export default options
</script>

<template>
    <div class="nyk-form nyk-js--form nyk-form-choice nyk-form--calculator">
        <div class="nyk-form-choice__label">{{ label }}</div>
        <div class="nyk-form-choice__content">
            <!-- Loop -->
            <template v-for="(option, index) in radioOptions" :key="index">
                <div class="nyk-form-choice__content__item nyk-js--choice-wrapper">
                    <div class="nyk-form-choice__content__item__choice">
                        <input
                            class="nyk-form-choice__content__item__choice__input"
                            type="radio"
                            :value="option.value"
                            v-model="localValue"
                            :name="inputName"
                            :checked="option.checked"
                            :autocomplete="autocomplete"
                            :id="`${inputName}-${index}`" />
                        <div class="nyk-form-choice__content__item__choice__circle">
                            <div class="nyk-form-choice__content__item__choice__circle__outer"></div>
                            <div class="nyk-form-choice__content__item__choice__circle__inner"></div>
                        </div>
                    </div>
                    <label class="nyk-form-choice__content__item__label" :for="`${inputName}-${index}`">{{ option.label }}</label>
                </div>
            </template>
        </div>

        <span class="nyk-form-choice__help-message" v-if="helpMessageLabel" aria-live="polite">{{ helpMessageLabel }}</span>
        <span class="nyk-form-choice__error-message" v-if="inputHasValidationErrors && isValidationError" aria-live="polite">{{
            errorMessageLabel
        }}</span>
    </div>
</template>
